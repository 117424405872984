/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createReducer, on } from '@ngrx/store';
import { ContratoApiActions } from '.';

const initialShowroomState = {
  loading: false,
  loadingInit: false,
  showrooms: [],
};

export const showroomReducer = createReducer(
  initialShowroomState,

  on(ContratoApiActions.loadShowroomInit, (state) => {
    return { ...state, loading: true, loadingInit: true };
  }),

  on(ContratoApiActions.loadShowroom, (state) => {
    return { ...state, loading: true, loadingInit: false };
  }),

  on(ContratoApiActions.loadSuccessShowroom, (state, { showrooms }) => {
    return {
      ...state,
      showrooms,
      loading: false,
    };
  }),

  on(ContratoApiActions.resetStore, () => {
    return initialShowroomState;
  })
);

const initialDetailContratoState = {
  loading: false,
  contrato: {
    car: null,
    buyer: null,
    seller: null,
    sale: null,
    files: null,
    solicitudModifica: null,
  },
  savedCar: false,
  savedBuyer: false,
  savedSeller: false,
  savedSale: false,
  updateSale: false,
  generatePDF: false,

  asociate: [],
};

export const detailContratoReducer = createReducer(
  initialDetailContratoState,

  on(ContratoApiActions.loadInitDetailContrato, (state) => {
    return { ...state, loading: true };
  }),

  on(ContratoApiActions.loadSuccessDetailContrato, (state, action) => {
    return {
      ...state,
      contrato: { ...action.contrato },
      loading: false,
    };
  }),

  on(ContratoApiActions.savedSaleSuccess, (currentState, action) => {
    return {
      ...currentState,
      contrato: { ...currentState.contrato, sale: action.sale },
      savedSale: false,
    };
  }),

  on(ContratoApiActions.savedCarSuccess, (currentState, action) => {
    return {
      ...currentState,
      contrato: { ...currentState.contrato, car: action.car },
      savedCar: false,
    };
  }),

  on(ContratoApiActions.savedUserSuccessBuyer, (currentState, action) => {
    return {
      ...currentState,
      contrato: { ...currentState.contrato, buyer: action.buyer },
      savedBuyer: false,
    };
  }),

  on(ContratoApiActions.savedUserSuccessSeller, (currentState, action) => {
    return {
      ...currentState,
      contrato: { ...currentState.contrato, seller: action.seller },
      savedSeller: false,
    };
  }),

  on(ContratoApiActions.setContratoBuyer, (currentState, action) => {
    return {
      ...currentState,
      contrato: { ...currentState.contrato, ...action },
      savedBuyer: true,
    };
  }),

  on(ContratoApiActions.setContratoSeller, (currentState, action) => {
    return {
      ...currentState,
      contrato: { ...currentState.contrato, ...action },
      savedSeller: true,
    };
  }),

  on(
    ContratoApiActions.savedChangesSale,
    ContratoApiActions.savedChangesCar,
    ContratoApiActions.savedChangesBuyer,
    ContratoApiActions.savedChangesSeller,
    (currentState, action) => {
      return {
        ...currentState,
        ...action,
      };
    }
  ),

  on(ContratoApiActions.updateContratoPDFFinallySuccess, (state) => {
    return {
      ...state,
      generatePDF: true,
    };
  }),

  on(ContratoApiActions.resetStore, () => {
    return initialDetailContratoState;
  }),

  on(ContratoApiActions.loadSuccessAsociate, (state, action) => {
    return {
      ...state,
      asociate: action.asociate,
    };
  })
);
