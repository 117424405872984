import { createSelector } from '@ngrx/store';
import { AppState } from '../../../app.state';

export const selectShowroomFeature = (state: AppState) => state.showroomState;
export const selectShowroom = createSelector(selectShowroomFeature, (state) => state.showrooms);
export const selectShowroomInit = createSelector(selectShowroomFeature, (state) => state);

export const selectContratoFeature = (state: AppState) => state.contratoState;
export const selectContratoAll = createSelector(selectContratoFeature, (state) => state.contrato);
export const selectContratoCar = createSelector(selectContratoFeature, (state) => state.contrato.car);
export const selectContratoSale = createSelector(selectContratoFeature, (state) => state.contrato.sale);
export const selectContratoSaleId = createSelector(
  selectContratoFeature,
  (state) => state.contrato?.sale?.id ?? undefined
);
export const selectSeller = createSelector(selectContratoFeature, (state) => state.contrato.seller);
export const selectBuyer = createSelector(selectContratoFeature, (state) => state.contrato.buyer);
export const selectVentaFiles = createSelector(selectContratoFeature, (state) => state.contrato.files);

export const selectSolModifica = createSelector(selectContratoFeature, (state) => state.contrato.solicitudModifica);

export const selectSavedChangesCar = createSelector(selectContratoFeature, (state) => state.savedCar);
export const selectSavedChangesBuyer = createSelector(selectContratoFeature, (state) => state.savedBuyer);
export const selectSavedChangesSeller = createSelector(selectContratoFeature, (state) => state.savedSeller);
export const selectSavedChangesSale = createSelector(selectContratoFeature, (state) => state.savedSale);
export const selectGeneratePDF = createSelector(selectContratoFeature, (state) => state.generatePDF);

export const selectAsociate = createSelector(selectContratoFeature, (state) => state.asociate);
